/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Initial Release
 */

import React, { Component } from 'react'
import { Link } from 'gatsby'
import burgerIcon from './burger.svg'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = { scrollClass: '' }
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll = () => {
    let scrollPosY
    let scrollClass
    if (typeof window !== 'undefined') {
      scrollPosY = window.pageYOffset
      if (scrollPosY > 0) {
        scrollClass = 'bg-white'
      } else {
        scrollClass = ''
      }
      this.setState({ scrollClass: scrollClass })
    }
  }

  render () {
    return (
      <header className={` ${ this.props.className }`}>
        <nav
          className={
            `navbar navbar-expand-md fixed-top navbar-light
            ${ this.state.scrollClass }
            `}
        >
          <Link
            to="/"
            title={this.props.siteTitle}
            className={`p-0 navbar-brand`}
          >
            <span>{this.props.siteTitle}</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <img
                src={burgerIcon}
                alt="Tools icon"
              /></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarToggler"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/services" className="nav-link">Services</Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link">Blog</Link>
              </li>
              <li className="nav-item">
                <Link to="/projects" className="nav-link">Open Source</Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">About</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">Contact</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
