/**
 * This file is part of ${PROJECT_NAME}
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud ${YEAR} <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Initial Release
 */

import React, { Component } from 'react'
import './../../styles/main.scss'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import Banner from './../../components/Privacy/Banner'

/* FontAwesome */
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

config.autoAddCss = false

library.add(fab, fas, far)
/* ./FontAwesome */

class DefaultLayout extends Component {
  scripts = () => {
    if (process.env.NODE_ENV === 'production') {
      return ([
          <script src="/jquery.min.js"></script>,
          <script src="/bootstrap.bundle.min.js"></script>
        ]
      )
    } else {
      require('../../../node_modules/jquery/dist/jquery')
      require('../../../node_modules/popper.js/dist/popper')
      require('../../../node_modules/bootstrap/dist/js/bootstrap')
    }
  }

  render () {
    return (
      <>
        <Banner/>
        <Header siteTitle={`Keith Mifsud`}/>
        <main className={this.props.className}>
          {this.props.children}
        </main>
        <Footer className={`mt-lg-17 mt-10`}/>
        {this.scripts()}
      </>
    )
  }
}

export default DefaultLayout
