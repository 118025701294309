/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import urlPropType from 'url-prop-type'
import requiredIf from 'react-required-if'
import Helmet from 'react-helmet'
import config from './../../data/SiteConfig'
import { capitaliseFirstLetter } from '../../../lib/helpers'

/**
 * Renders Seo meta data and structured data.
 */
class Seo extends Component {
  /**
   * Gets the Structured Data for the page.
   *
   * @param pageType
   * @param pageUrl
   * @param fullPageTitle
   * @param pageDescription|null
   * @return {Array}
   */
  getStructuredData = (
    pageType,
    pageUrl,
    fullPageTitle,
    pageDescription = null
  ) => {
    let structuredData = []

    if (pageType === 'website') {
      structuredData.push(this.getWebsiteStructuredData(fullPageTitle))
    }
    if (pageType === 'article-list') {
      structuredData.push(this.getWebPageStructuredData(
        fullPageTitle,
        pageUrl,
        pageDescription
      ))
      structuredData.push(this.getStructuredBreadcrumbList(pageUrl))
    }
    if (pageType === 'article') {
      structuredData.push(this.getArticleStructuredData())
      structuredData.push(this.getStructuredBreadcrumbList(pageUrl))
    }
    if (pageType === 'webpage') {
      structuredData.push(this.getWebPageStructuredData(
        fullPageTitle,
        pageUrl,
        pageDescription
      ))
      structuredData.push(this.getStructuredBreadcrumbList(pageUrl))
    }

    return structuredData
  }

  /**
   * Gets the Twitter cards' meta elements.
   *
   * @param pageType
   * @param pageDescription
   * @param fullPageTitle
   * @param pageImagePath
   * @return {*}
   */
  getTwitterMetaData = (
    pageType,
    pageDescription,
    fullPageTitle,
    pageImagePath
  ) => {
    return ([
      <meta
        key={`twitter:card`}
        name={`twitter:card`}
        content={this.getTwitterCardType(pageType)}
      />,
      <meta
        key={`twitter:creator`}
        name={`twitter:creator`}
        content={config.twitterAuthor}
      />,
      <meta
        key={`twitter:site`}
        name={`twitter:site`}
        content={config.twitterAuthor}
      />,
      <meta
        key={`twitter:description`}
        name={`twitter:description`}
        content={pageDescription}
      />,
      <meta
        key={`twitter:title`}
        name={`twitter:title`}
        content={fullPageTitle}
      />,
      <meta
        key={`twitter:image`}
        name={`twitter:image`}
        content={this.getPageImageUrl(pageImagePath)}
      />
    ])
  }

  /**
   * Gets the Open Graph Meta dara.
   *
   * @param pagePath
   * @param pageType
   * @param fullPageTitle
   * @param pageDescription
   * @param pageImagePath
   * @param articlePublishDate,
   * @param articleTags
   * @return {*[]}
   */
  getOgMetaData = (
    pagePath,
    pageType,
    fullPageTitle,
    pageDescription,
    pageImagePath,
    articlePublishDate,
    articleTags
  ) => {
    let metaData = [
      <meta
        key={`og:url`}
        property={`og:url`}
        content={this.getPageUrl(pagePath)}
      />,
      <meta
        key={`og_type`}
        property={'og:type'}
        content={this.getOgType(pageType)}
      />,
      <meta
        key={`og:title`}
        property={`og:title`} content={fullPageTitle}
      />,
      <meta
        key={`og:description`}
        property={`og:description`}
        content={pageDescription}
      />,
      <meta
        key={`og:site_name`}
        property={`og:site_name`}
        content={config.siteTitleAlt}
      />,
      <meta
        key={`og:image`}
        property={`og:image`}
        content={this.getPageImageUrl(pageImagePath)}
      />,
      <meta
        key={`fb:pages`}
        property="fb:pages"
        content={config.facebookPageID}
      />,
    ]

    if (pageType === 'article') {
      metaData.push(
        <meta
          key={`article:author`}
          property={`article:author`}
          content={`https://facebook.com/${ config.facebookPageID }`}
        />
      )
      metaData.push(
        <meta
          key={`article:published_time`}
          property={`article:published_time`}
          content={articlePublishDate}
        />
      )
      metaData.push(
        <meta
          key={`article:section`}
          property={`article:section`}
          content={config.openGraphDefaultSection}
        />
      )
      articleTags.forEach(function (tag, index) {
        metaData.push(
          <meta
            key={index}
            property={`article:tag`}
            content={tag}
          />
        )
      })
    }

    return metaData
  }

  /**
   * Gets the canonical tag.
   *
   * @return {*}
   */
  getCanonicalTag = () => {
    let url = this.getPageUrl(this.props.pagePath)
    if (this.props.canonicalUrl) {
      url = this.props.canonicalUrl
    }

    return (
      <link rel={`canonical`} href={url}/>
    )
  }
  /**
   * Gets the page's URL.
   *
   * @param pagePath
   * @return {string}
   */
  getPageUrl = pagePath => {
    return config.siteUrl + pagePath
  }

  /**
   * Gets the full image URL.
   *
   * @param pageImagePath
   * @return {string}
   */
  getPageImageUrl = pageImagePath => {
    return config.siteUrl + pageImagePath
  }

  /**
   * Gets the OG type.
   *
   * @param pageType
   * @return {string}
   */
  getOgType = pageType => {
    let ogType
    switch (pageType) {
    case 'website':
      ogType = 'website'
      break
    case 'article-list':
      ogType = 'website'
      break
    case 'article':
      ogType = 'article'
      break
    case 'webpage':
      ogType = 'website'
      break
    default:
      let error = pageType + ' is an invalid OG type option.'
      return new Error(error)
    }
    return ogType
  }

  /**
   * Gets the twitter's card type.
   * @param pageType
   * @return {string}
   */
  getTwitterCardType = pageType => {
    let cardType
    switch (pageType) {
    case 'website' :
      cardType = 'summary_large_image'
      break
    case 'article-list':
      cardType = 'summary_large_image'
      break
    case 'article':
      cardType = 'summary_large_image'
      break
    case 'webpage':
      cardType = 'summary_large_image'
      break
    default:
      cardType = 'summary'
    }
    return cardType
  }

  /**
   * Get the Breadcrumbs list.
   *
   * @param pageUrl
   * @return {object}
   */
  getStructuredBreadcrumbList = pageUrl => {
    return {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': this.getBreadcrumbItems(pageUrl)
    }
  }

  /**
   * Get the Breadcrumb items.
   *
   * @param pageUrl
   * @return {object}
   */
  getBreadcrumbItems = pageUrl => {
    let items = []

    // separate url into array items.
    let parts = pageUrl.split(config.siteUrl)[1]
    parts = parts.split('/')

    // for each item add a ListItem.
    parts.forEach(function (part, index) {
      let name
      let url
      if (index === 0) {
        name = config.siteTitle
        url = config.siteUrl
      } else {
        name = capitaliseFirstLetter(part)
        url = config.siteUrl + '/' + part
      }
      items.push(
        {
          '@type': 'ListItem',
          'position': index + 1,
          'name': name,
          'item': url
        }
      )
    })

    return items
  }

  /**
   * Get Structured Data for the WebPage entity.
   * @param pageTitle
   * @param pageUrl
   * @param pageDescription
   * @return {object}
   */
  getWebPageStructuredData = (pageTitle, pageUrl, pageDescription) => {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      'name': pageTitle,
      'url': pageUrl,
      'image': this.getPageImageUrl(config.siteImage),
      'description': pageDescription,
      'author': this.getDefaultAuthorStructuredData(),
      'publisher': this.getDefaultPublisherStructuredData(),
    }
  }

  /**
   * Gets Structured Data for the WebSite entity.
   *
   * @return {object}
   */
  getWebsiteStructuredData = fullPageTitle => {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': config.siteUrl,
      'url': config.siteUrl,
      'name': config.siteTitle,
      'image': this.getPageImageUrl(config.siteImage),
      'alternateName': fullPageTitle,
      'description': config.metaDescription,
      'author': this.getDefaultAuthorStructuredData(),
      'publisher': this.getDefaultPublisherStructuredData()
    }
  }

  /**
   * Gets the Structured Data for Article
   *
   * @return {object}
   */
  getArticleStructuredData = () => {
    return {
      '@context': 'http://schema.org',
      '@type': 'Article',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': this.getPageUrl(this.props.pagePath)
      },
      'headline': this.props.fullPageTitle,
      'description': this.props.pageDescription,
      'image': [
        this.getPageImageUrl(this.props.pageImage)
      ],
      'datePublished': this.props.articlePublishDate,
      'dateModified': this.props.articlePublishDate,
      'author': this.getDefaultAuthorStructuredData(),
      'publisher': this.getDefaultPublisherStructuredData()
    }
  }

  /**
   * Gets the default Author.
   * @return {object}
   */
  getDefaultAuthorStructuredData = () => {
    return {
      '@type': 'Person',
      '@id': config.defaultAuthor.url,
      'name': config.defaultAuthor.name,
      'email': config.defaultAuthor.emailAddress,
      'image': this.getPageImageUrl(config.defaultAuthor.image),
      'jobTitle': config.defaultAuthor.jobTitle,
      'gender': config.defaultAuthor.gender,
      'nationality': config.defaultAuthor.nationality,
      'url': config.defaultAuthor.url,
      'sameAs': [
        config.defaultAuthor.socialProfiles.facebook,
        config.defaultAuthor.socialProfiles.twitter,
        config.defaultAuthor.socialProfiles.linkedIn
      ],
      'address': {
        '@type': 'PostalAddress',
        '@id': config.defaultAuthor.address.id,
        'streetAddress': config.defaultAuthor.address.street,
        'addressLocality': config.defaultAuthor.address.city,
        'addressRegion': config.defaultAuthor.address.region,
        'addressCountry': config.defaultAuthor.address.country,
        'postalCode': config.defaultAuthor.address.postalCode
      }
    }
  }

  /**
   * Gets the default Publisher.
   *
   * @return {object}
   */
  getDefaultPublisherStructuredData = () => {
    return {
      '@type': 'Organization',
      '@id': config.siteUrl,
      'name': config.siteTitle,
      'email': config.defaultAuthor.emailAddress,
      'logo': {
        '@type': 'ImageObject',
        'url': this.getPageImageUrl(config.siteImage)
      },
      'url': config.siteUrl,
      'sameAs': [
        config.defaultAuthor.socialProfiles.facebook,
        config.defaultAuthor.socialProfiles.twitter,
        config.defaultAuthor.socialProfiles.linkedIn
      ],
      'address': {
        '@type': 'PostalAddress',
        '@id': config.defaultAuthor.address.id,
        'streetAddress': config.defaultAuthor.address.street,
        'addressLocality': config.defaultAuthor.address.city,
        'addressRegion': config.defaultAuthor.address.region,
        'addressCountry': config.defaultAuthor.address.country,
        'postalCode': config.defaultAuthor.address.postalCode
      }
    }
  }

  render () {
    const {
      lang,
      pageType,
      pageTitle,
      fullPageTitle,
      pageDescription,
      pageImage,
      pagePath,
      articlePublishDate,
      articleTags
    } = this.props
    return (
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={pageTitle}
        titleTemplate={`%s | ${ config.siteTitle }`}
      >
        <meta name={`description`} content={pageDescription}/>

        <meta
          name={`image`}
          content={this.getPageImageUrl(pageImage)}
        />

        {this.getCanonicalTag()}

        {this.getOgMetaData(
          pagePath,
          pageType,
          fullPageTitle,
          pageDescription,
          pageImage,
          articlePublishDate,
          articleTags
        )}

        {this.getTwitterMetaData(
          pageType,
          pageDescription,
          fullPageTitle,
          pageImage
        )}

        <script type={`application/ld+json`}>
          {JSON.stringify(
            this.getStructuredData(
              pageType,
              this.getPageUrl(pagePath),
              fullPageTitle,
              pageDescription
            )
          )}
        </script>
      </Helmet>
    )
  }
}

Seo.defaultProps = {
  lang: 'en',
}

Seo.propTypes = {
  lang: PropTypes.string,
  pageType: PropTypes.oneOf([
    'website',
    'article-list',
    'article',
    'webpage'
  ]).isRequired,
  pageTitle: PropTypes.string.isRequired,
  fullPageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageImage: PropTypes.string.isRequired,
  pagePath: PropTypes.string.isRequired,
  articlePublishDate: requiredIf(
    PropTypes.string,
    props => props.pageType === 'article'
  ),
  articleTags: requiredIf(
    PropTypes.arrayOf(PropTypes.string),
    props => props.pageType === 'article'
  ),
  canonicalUrl: urlPropType
}

export default Seo
