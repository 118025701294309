/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from './../../data/SiteConfig'

class Footer extends Component {
  render () {
    return (
      <footer className={ ` ${ this.props.className }` }>
        <div className="container border-top">
          <div className="row justify-content-start pt-lg-3">
            <div className="col-12 col-lg-2">
              <ul className="nav flex-column text-left">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/blog`}
                    title={`Blog`}>
                    Blog</Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/services`}
                    title={`Services`}>
                    Services</Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/projects`}
                    title={`Projects`}>
                    Open Source</Link>
                </li>
              </ul>
            </div>
            <div className="col-5 pt-3 pt-lg-0 col-lg-2">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/about`}
                    title={`About Keith Mifsud`}>
                    About</Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/subscribe`}
                    title={`Subscribe`}>
                    Subscribe</Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/contact`}
                    title={`Contact`}>
                    Get in touch</Link>
                </li>
              </ul>
            </div>
            <div className="col-7 col-lg-3 offset-lg-5 text-right pt-lg-0 pt-2">
              <ul className="nav text-right justify-content-end social-links">
                <li className="nav-item">
                  <a
                    href={config.defaultAuthor.socialProfiles.linkedIn}
                    className="nav-link"
                    title={`Connect on LinkedIn`}
                    target={`_blank`}
                    rel={`noopener norefferer`}
                  ><FontAwesomeIcon icon={['fab', 'linkedin']}/>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href={config.defaultAuthor.socialProfiles.gitHub}
                    className="nav-link"
                    title={`View my GitHub profile`}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                  ><FontAwesomeIcon icon={['fab', 'github']}/>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href={config.defaultAuthor.socialProfiles.twitter}
                    className="nav-link"
                    title={`Follow Keith Mifsud on Twitter`}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                  ><FontAwesomeIcon icon={['fab', 'twitter']}/>
                  </a>
                </li>
              </ul>
              <p className={`mb-0 mt-2`}>
                <small className={`text-muted`}>
                  <a href="mailto:mifsud.k@gmail.com?Subject=Web enquiry - general">mifsud.k@gmail.com</a>
                </small>
              </p>
              <ul className="nav text-right justify-content-end legal-links">
                <li className="nav-item">
                  <Link className="nav-link first" to={`/privacy-policy`}>
                    <small>Privacy policy</small>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/terms-and-conditions`}>
                    <small>Terms of use</small>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-6 mb-lg-6 mb-3 justify-content-center">
            <div className="col-12 col-lg-4 text-center">
              <small className={`text-muted`}>
                &copy; Copyright Keith Mifsud 2016 - 2023
              </small>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
