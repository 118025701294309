/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

/**
 * Determines if the screen is small.
 *
 * @return {boolean}
 */
export function isSmallScreen () {
  if (typeof window !== 'undefined') {
    let viewportWidth = window.innerWidth ||
      document.documentElement.clientWidth

    return viewportWidth < 768
  }
}

export function isDesktop () {
  if (typeof window !== 'undefined') {
    let viewportWidth = window.innerWidth ||
      document.documentElement.clientWidth
    return viewportWidth > 1025
  }
}

/**
 * Capitalises the first letter.
 *
 * @param word
 * @return {string}
 */
export function capitaliseFirstLetter (word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

/**
 * Checks if the item is the last in array.
 *
 * @param index
 * @param totalItems
 * @return {boolean}
 */
export function isLastElementInArray (index, totalItems) {
  return ((totalItems - 1) === index)
}
