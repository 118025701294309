/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Initial Release
 */
module.exports = {
  siteTitle: 'Keith Mifsud',
  siteTitleAlt: 'Keith Mifsud Software Engineer',
  twitterUrl: 'https://twitter.com/keithmifsud',
  siteUrl: 'https://keith-mifsud.me',
  siteImage: '/icons/icon-512x512.png',
  metaDescription: 'An independent Software Engineer focused on business and #IOT applications. Providing consultancy, workshops, project and team lead, full stack development and technical authoring services.',
  twitterAuthor: '@keithmifsud',
  facebookPageID: '565835443517289',
  openGraphDefaultSection: `Technology`,
  rssFeedPath: '/feed.xml',
  disqusShortname: 'keith-mifsud',
  defaultAuthor: {
    name: 'Keith Mifsud',
    emailAddress: 'mifsud.k@gmail.com',
    jobTitle: 'Software Engineer',
    image: '/icons/icon-512x512.png',
    gender: 'Male',
    nationality: 'Maltese',
    url: 'https://keith-mifsud.me/about',
    relative_url: '/about',
    address: {
      id: 'https://keith-mifsud.me/about/address',
      street: '30, Fastolff House, Regent Street',
      city: 'Great Yarmouth',
      region: 'Norfolk',
      postalCode: 'NR30 1RR',
      country: 'GB'
    },
    socialProfiles: {
      facebook: 'https://facebook.com/mifsudKeith',
      twitter: 'https://twitter.com/keithmifsud',
      linkedIn: 'https://www.linkedin.com/in/keith-mifsud',
      googlePlus: 'https://plus.google.com/100921743408799501015', // deprecated
      gitHub: 'https://github.com/keithmifsud'
    }
  },
}
