/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import CookieBanner from 'react-cookie-banner'
import './styles.scss'

/**
 * User consent to the privacy policy.
 */
class ConsentBanner extends Component {
  render () {
    return (
      <div>
        <CookieBanner
          styles={{
            banner: {
              position: 'fixed',
              textAlign: 'center',
              backgroundColor: 'rgb(72, 72, 72)',
              width: '100%',
              height: '60px',
              zIndex: '10000',
              bottom: '0'
            }
          }}
          buttonMessage={`I understand`}
          dismissOnScroll={false}
          message={`Please note that unless you tell me otherwise, you are agreeing to this`}
          link={<a href='/privacy-policy'>privacy policy</a>}
        />
      </div>
    )
  }
}

export default ConsentBanner
